import React from 'react'

import Login from './Login'
import Messanger from './Messanger'

export const SERVER = 'https://penguin-utility-server.herokuapp.com'
// export const SERVER = 'http://localhost:3001'

export default function App() {
  const [auth, setAuth] = React.useState(localStorage.getItem('auth') || '')

  return auth ? (
    <Messanger auth={auth} setAuth={setAuth} />
  ) : (
    <Login setAuth={setAuth} />
  )
}
