import React, { Fragment, useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles, fade } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import SendIcon from '@material-ui/icons/Send'
import MoreIcon from '@material-ui/icons/MoreVert'
import SearchIcon from '@material-ui/icons/Search'

import io from 'socket.io-client'
import moment from 'moment'

const icon = './logo192.png'

// import { SERVER } from './App'
// export const SERVER = 'http://localhost:3001'
export const SERVER = 'https://penguin-utility-server.herokuapp.com'

const socket = io(SERVER)

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  appBar: {
    bottom: 'unset',
    left: 'unset',
    right: 'unset',
    position: 'unset'
  },
  grow: {
    flexGrow: 1
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'hidden'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  usersPaper: {
    height: '100%',
    width: '450px',
    display: 'flex',
    paddingTop: 0,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  usersHide: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  fixedHeight: {
    height: 240
  },
  chatContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  messagesContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  converesationContainer: {
    flexGrow: 1,
    overflow: 'scroll',
    padding: '16px 64px',
    display: 'flex',
    flexDirection: 'column-reverse',

    [theme.breakpoints.down('md')]: {
      padding: '16px'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  text: {
    padding: theme.spacing(2, 2, 0)
  },
  list: {
    marginBottom: theme.spacing(2),
    backgroundColor: 'white',
    flexGrow: 1,
    overflow: 'scroll'
  },
  textareaContainer: {
    backgroundColor: '#eee',
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  send: {
    marginLeft: theme.spacing(2)
  },
  messageContainer: {
    maxWidth: '500px',
    display: 'flex'
  },
  messageContainerMe: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  messageBox: {
    padding: '8px 16px',
    borderRadius: '4px',
    backgroundColor: '#f2f2f2'
  },
  timeBox: {
    display: 'flex',
    marginTop: '8px',
    justifyContent: 'flex-end'
  },
  listItemSec: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

export default function Messanger({ auth, setAuth }) {
  const classes = useStyles()
  const [chatList, setChatList] = useState([])
  const [chatListRender, setChatListRender] = useState(chatList)
  const [chatData, setChatData] = useState({})
  const [chatContacts, setChatContacts] = useState([])
  const [message, setMessage] = useState('')
  const [search, setSearch] = useState('')
  const [active, setActive] = useState(false)
  const [activeContact, setActiveContact] = useState({})
  const [error, setError] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const findContact = number => {
    return chatContacts.find(x => x.number === number) || {}
  }

  const contactWindow = e => {
    if (e.lastContact == null) return false

    return moment(e.lastContact)
      .add(1, 'days')
      .isAfter(new Date())
  }

  function handleSend() {
    if (message.trim().length > 0) {
      fetch(`${SERVER}/wa/send`, {
        method: 'post',
        body: JSON.stringify({ number: active, body: message }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: auth
        }
      })
        .then(response => {
          setMessage('')
          return response.json()
        })
        .catch(err => {
          if (err) setError(err)
        })
    }
  }

  function handleBack() {
    setActive(false)
  }

  function handleLogout() {
    localStorage.clear()
    setAuth('')
  }

  useEffect(() => {
    if (search) {
      setChatListRender(
        chatList.filter(
          x => x.number.includes(search) || (x.name || '').includes(search)
        )
      )
    } else {
      setChatListRender(chatList)
    }
  }, [chatList, search])

  useEffect(() => {
    setActiveContact(chatContacts.find(x => x.number === active) || {})
  }, [active, chatContacts])

  useEffect(() => {
    fetch(`${SERVER}/wa/data`, {
      headers: {
        Authorization: auth
      }
    })
      .then(res => res.json())
      .then(({ messages, contacts }) => {
        const chtList = []
        const chtData = {}
        messages.reverse().map(e => {
          if (chtList.find(c => c.number === e.number)) {
            chtData[e.number].messages.push({
              msg: e.body,
              time: e.createdAt,
              type: e.type,
              status: e.status,
              id: e.messageSid,
              media: e.mediaUrl,
              contentType: e.mediaContentType
            })
          } else {
            chtList.push(contacts.find(x => x.number === e.number))
            chtData[e.number] = {
              messages: [
                {
                  msg: e.body,
                  time: e.createdAt,
                  type: e.type,
                  status: e.status,
                  id: e.messageSid,
                  media: e.mediaUrl,
                  contentType: e.mediaContentType
                }
              ]
            }
          }
        })

        setChatList(chtList)
        setChatData(chtData)
        setChatContacts(contacts)
      })
  }, [auth])

  useEffect(() => {
    function incommingData(data) {
      const i = chatList.findIndex(e => e.number === data.number)

      if (i === -1) {
        setChatList([{ number: data.number, name: '' }, ...chatList])

        setChatData({
          ...chatData,
          [data.number]: {
            messages: [
              {
                msg: data.body,
                time: data.createdAt,
                type: data.type,
                status: data.status,
                id: data.messageSid,
                media: data.mediaUrl,
                contentType: data.mediaContentType
              }
            ]
          }
        })
      } else {
        setChatList([
          chatList[i],
          ...chatList.slice(0, i),
          ...chatList.slice(i + 1)
        ])

        setChatData({
          ...chatData,
          [data.number]: {
            messages: [
              {
                msg: data.body,
                time: data.createdAt,
                type: data.type,
                status: data.status,
                id: data.messageSid,
                media: data.mediaUrl,
                contentType: data.mediaContentType
              },
              ...chatData[data.number].messages
            ]
          }
        })
      }
      Notification.requestPermission().then(function(result) {
        if (result === 'granted') {
          if (!data.type)
            new Notification(`New Message from ${data.number}`, {
              body: data.body,
              badge: icon,
              icon: icon,
              image: icon
            })
        }
      })
    }
    socket.on('msg', incommingData)
    return () => {
      socket.off('msg', incommingData)
    }
  }, [chatData, chatList])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.chatContainer}>
          <Paper
            square
            className={clsx(
              classes.paper,
              classes.usersPaper,
              active && classes.usersHide
            )}
          >
            <Typography className={classes.text} variant="h5" gutterBottom>
              Inbox
            </Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
            <List className={classes.list}>
              {chatListRender.map(({ number }, i) => (
                <React.Fragment key={number}>
                  {/* {i === 1 && (
                    <ListSubheader className={classes.subheader}>
                      Today
                    </ListSubheader>
                    )}
                    {i === 3 && (
                    <ListSubheader className={classes.subheader}>
                      Yesterday
                    </ListSubheader>
                  )} */}
                  <ListItem button onClick={() => setActive(number)}>
                    <ListItemAvatar>
                      {contactWindow(findContact(number)) ? (
                        <Badge
                          color="primary"
                          overlap="circle"
                          badgeContent=" "
                        >
                          <Avatar
                            alt="Profile Picture"
                            src={`https://www.gravatar.com/avatar/${
                              findContact(number).gravatar
                            }.jpg?d=mp`}
                          />
                        </Badge>
                      ) : (
                        <Avatar
                          alt="Profile Picture"
                          src={`https://www.gravatar.com/avatar/${
                            findContact(number).gravatar
                          }.jpg?d=mp`}
                        />
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      classes={{ secondary: classes.listItemSec }}
                      primary={
                        findContact(number).name ||
                        number.replace('whatsapp:', '')
                      }
                      secondary={
                        chatData[number] ? chatData[number].messages[0].msg : ''
                      }
                    />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
            <AppBar color="primary" className={classes.appBar}>
              <Toolbar>
                <div className={classes.grow} />
                <IconButton edge="end" color="inherit" onClick={handleClick}>
                  <MoreIcon />
                </IconButton>

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Toolbar>
            </AppBar>
          </Paper>
          {active ? (
            <div className={classes.messagesContainer}>
              <AppBar position="static" style={{ marginBottom: 16 }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={handleBack}
                  >
                    <KeyboardBackspaceIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    {activeContact.name || active.replace('whatsapp:', '')}
                  </Typography>
                  {activeContact.acID ? (
                    <Button
                      target="_blank"
                      href={`https://penguin.activehosted.com/app/contacts/${activeContact.acID}`}
                      color="inherit"
                    >
                      open AC
                    </Button>
                  ) : (
                    ''
                  )}
                </Toolbar>
              </AppBar>
              <div className={classes.converesationContainer}>
                {chatData[active].messages.map(e => (
                  <div key={e.id}>
                    <div
                      style={{ marginBottom: 16 }}
                      className={clsx(e.type && classes.messageContainerMe)}
                    >
                      <div className={clsx(classes.messageContainer)}>
                        <Avatar
                          alt="Profile Picture"
                          src={
                            e.type
                              ? ''
                              : `https://www.gravatar.com/avatar/${activeContact.gravatar}.jpg?d=mp`
                          }
                          style={{ marginRight: 16 }}
                        />
                        <div>
                          <div className={classes.messageBox}>
                            {e.media ? (
                              e.contentType.includes('image') ? (
                                <img
                                  src={e.media}
                                  alt=""
                                  style={{ maxWidth: '100%' }}
                                />
                              ) : (
                                <a href={e.media} download>
                                  {e.msg}
                                </a>
                              )
                            ) : (
                              e.msg.split('\n').map((item, key) => {
                                return (
                                  <Fragment key={key}>
                                    {item}
                                    <br />
                                  </Fragment>
                                )
                              })
                            )}
                          </div>
                          <div className={classes.timeBox}>
                            <Typography variant="caption">
                              {moment(e.time).fromNow()}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className={classes.textareaContainer}>
                <TextField
                  label="Message"
                  multiline
                  rowsMax="4"
                  variant="outlined"
                  fullWidth
                  value={message}
                  onChange={e => {
                    if (e.nativeEvent.inputType !== 'insertLineBreak')
                      setMessage(e.target.value)
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      if (e.shiftKey) {
                        setMessage(e.target.value + '\n')
                      } else {
                        handleSend()
                      }
                    }
                  }}
                />
                <IconButton onClick={handleSend} className={classes.send}>
                  <SendIcon fontSize="large" />
                </IconButton>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </main>
    </div>
  )
}
